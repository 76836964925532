import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  HorizontalLayout,
  Section,
} from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { PricingMainBannerContent } from '@/features/new-landing-page/pages/pricing-content';
import { TabSelect } from '@/components/tab-select';
import { CustomersScroller } from '@/features/new-landing-page/components/customers-scroller';
import { StrapiPricingPlanCard } from '@/features/strapi/components/pricing-plan-card';
import { groupBy } from '@/utils/arrays';
import { VSpace } from '@/components/spacing';
import { StrapiImageTextual } from '@/features/strapi/components/image-textual';
import { breakpoints } from '@/components/responsive';
import { GradientGlowContainer } from '@/features/new-landing-page/components/gradient-glow-container';

export function PricingMainBannerSection() {
  const [selectedPriceGroup, setSelectedPriceGroup] = React.useState(5000);
  const selectedPlans = useMemo(
    () =>
      PricingMainBannerContent.pricingPlans.plans.filter(
        // eslint-disable-next-line eqeqeq
        (plan) => plan.group == selectedPriceGroup,
      ),
    [selectedPriceGroup],
  );
  const plansGroups = Object.keys(
    groupBy(PricingMainBannerContent.pricingPlans.plans, (plan) => plan.group),
  ).map((group) => parseInt(group, 10));

  return (
    <CustomSection>
      <GradientGlowContainer />
      <StrapiTextual {...PricingMainBannerContent.title} />
      <StrapiTextual {...PricingMainBannerContent.subTitle} />
      <HorizontalLayout>
        {PricingMainBannerContent.benefitUsps.map((usp) => (
          <StrapiImageTextual key={usp.textual.text} {...usp} />
        ))}
      </HorizontalLayout>
      <Separator />
      <StrapiTextual {...PricingMainBannerContent.pricingPlans.superTitle} />
      <StrapiTextual {...PricingMainBannerContent.pricingPlans.title} />
      <TabSelect
        className="only-desktop"
        tabWidth={15}
        defaultValue="1,500 - 5,000"
        tabs={[`Up to 1500`, `1,500 - 5,000`, `5,000 - 30,000`, `Over 30,000`]}
        onTabSelected={(idx) => setSelectedPriceGroup(plansGroups[idx])}
        selectedColor="white"
        unselectedColor="#63707C"
      />
      <TabSelect
        className="only-mobile"
        tabHeight={3.5}
        tabWidth={8.2}
        defaultValue="1,500 - 5,000"
        tabs={[`Up to 1500`, `1,500 - 5,000`, `5,000 - 30,000`, `Over 30,000`]}
        onTabSelected={(idx) => setSelectedPriceGroup(plansGroups[idx])}
        selectedColor="white"
        unselectedColor="#63707C"
      />
      <StrapiTextual
        {...PricingMainBannerContent.pricingPlans.trail}
        className="trail"
      />
      <PlansWrapper>
        {selectedPlans.map((plan) => (
          <StrapiPricingPlanCard key={plan.ordersRange} {...plan} />
        ))}
      </PlansWrapper>
      <VSpace value={2} mobileValue={0.1} />
      <CustomersScroller />
      <VSpace value={1} />
    </CustomSection>
  );
}

const CustomSection = styled(Section)`
  .trail {
    background: rgba(243, 236, 229, 1);
    padding: 1rem 2rem;
    border-radius: 3rem;
    ${breakpoints.down(`md`)} {
      background: none;
    }
  }

  .tab-select-container {
    border-radius: 3rem;
    background-color: #ffffff;
    box-shadow: 0 3px 15px 0 rgba(253, 118, 0, 0.16);
    padding-top: 0;
    padding-bottom: 0;
  }

  .tab-select-tab {
    font-family: Eesti, sans-serif;
    font-size: 1.5rem;

    ${breakpoints.down(`md`)} {
      font-size: 1.1rem;
    }
  }

  .tab-select-selector {
    box-shadow: none;
    background: black;
  }

  ${breakpoints.down(`md`)} {
    && > * {
      margin-bottom: 2.5rem;
    }
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 45%;
  background: rgba(220, 218, 218, 0.5);

  ${breakpoints.down(`md`)} {
    width: 90%;
  }
`;

const PlansWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
  grid-auto-columns: min-content;

  ${breakpoints.down(`md`)} {
    overflow-x: scroll;
    max-width: 100vw;

    && > *:first-child {
      margin-left: 2rem;
    }

    && > *:last-child {
      margin-right: 4rem;
    }

    margin: -1rem;
    padding: 1rem;
  }

  && > * {
    min-width: 35rem;

    ${breakpoints.down(`md`)} {
      min-width: 25rem;
    }
  }
`;

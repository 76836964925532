import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { VSpace } from '@/components/spacing';
import { breakpoints } from '@/components/responsive';

export interface StrapiFaqProps {
  items: { question: string; answer: string }[];
}

export function StrapiFaq({ items }: StrapiFaqProps) {
  const [selected, setSelected] = React.useState(0);

  return (
    <Wrapper>
      {items.map((item, index) => (
        <Fade bottom>
          <LineItem key={item.question}>
            <StrapiTextual
              className="question"
              text={`${selected === index ? `-` : `+`}  ${item.question}`}
              weight="bold"
              size="2.4rem"
              onClick={() => setSelected(index)}
            />
            {selected === index && (
              <div className="answer-wrapper">
                <VSpace value={2} />
                <StrapiTextual
                  className="answer"
                  text={item.answer}
                  weight="light"
                  size="2rem"
                  lineHeight="1.8"
                />
              </div>
            )}
          </LineItem>
        </Fade>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 70vw;
  display: grid;
  grid-gap: 6rem;

  ${breakpoints.down(`md`)} {
    width: 85vw;
  }
`;

const LineItem = styled.div`
  .question {
    cursor: pointer;
  }

  @keyframes reveal {
    from {
      opacity: 0;
      transform: scaleY(0);
    }
    to {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .answer-wrapper {
    transform-origin: top;
    animation: reveal 0.5s ease-in-out;
  }

  .answer {
  }
`;

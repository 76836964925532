import React from 'react';
import styled from 'styled-components';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { VSpace } from '@/components/spacing';
import { breakpoints } from '@/components/responsive';

export interface StrapiPricingPlanCardProps {
  ordersRange: string;
  price: string;
  morePrice?: string;
  benefits: string[];
  cta?: string;
  group: number;
}

export function StrapiPricingPlanCard({
  ordersRange,
  price,
  morePrice,
  cta,
  benefits,
}: StrapiPricingPlanCardProps) {
  const onClick = () => {
    if (cta) {
      window.location.replace(`/public/talk-to-expert`);
    }
  };

  return (
    <Wrapper>
      <StrapiTextual
        text={ordersRange}
        className="orders-range"
        size="1.4rem"
      />
      <VSpace value={2} />
      <StrapiTextual text={price} className="price" size="4rem" />
      <VSpace value={1} />
      <StrapiTextual
        text={cta || `Start Free`}
        className="cta"
        size="1.7rem"
        href={
          cta
            ? undefined
            : `https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=pricing`
        }
        onClick={onClick}
      />
      {morePrice && (
        <>
          <VSpace value={1} />
          <StrapiTextual
            text={morePrice}
            className="more-price"
            size="1.5rem"
          />
        </>
      )}

      <VSpace value={2} />
      <Separator />
      <VSpace value={2} />
      <BenefitWrapper>
        {benefits.map((benefit) => (
          <Benefit>
            <IoCheckmarkCircleSharp size={16} />
            <StrapiTextual
              key={benefit}
              text={benefit}
              className="benefit"
              size="1.8rem"
            />
          </Benefit>
        ))}
      </BenefitWrapper>
      <VSpace value={8} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  align-content: flex-start;
  justify-items: flex-start;

  padding: 4rem;
  border: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 2.5rem;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03),
    0 12px 20px 0 rgba(177, 217, 203, 0.11), 0 2px 6px 0 rgba(0, 0, 0, 0.03);
  ${breakpoints.down(`md`)} {
    border-radius: 1.5rem;
  }
  .benefit {
    text-align: start;
    letter-spacing: -0.4px;
  }

  .cta {
    background: #dc4949;
    padding: 1.3rem 3.5rem;
    color: white;
    border-radius: 3rem;
    cursor: pointer;
    //align-self: flex-end;
    //position: absolute;
    //bottom: 4rem;
    //left: 4rem;
  }

  .cta:hover {
    opacity: 0.8;
  }

  .cta:active {
    opacity: 0.6;
  }

  .orders-range,
  .more-price {
    color: #475562;
    text-align: start;
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(151, 151, 151, 0.1);
`;

const BenefitWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.8rem;
`;

const Benefit = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

import React from 'react';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { Footer } from '@/features/new-landing-page/components/footer';
import { CaseStudiesSection } from '@/features/new-landing-page/sections/case-studies';
import { DisruptSection } from '@/features/new-landing-page/sections/disrupt';
import { PricingMainBannerSection } from '@/features/new-landing-page/sections/pricing-main-banner';
import { PricingFaqSection } from '@/features/new-landing-page/sections/pricing-faq';

export function Pricing() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <PricingMainBannerSection />
      <PricingFaqSection />
      <CaseStudiesSection noCta />
      <DisruptSection ctaHref="https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=pricing" />
      <Footer />
    </PageWrapper>
  );
}

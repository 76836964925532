import React from 'react';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { PricingFaqContent } from '@/features/new-landing-page/pages/pricing-content';
import {
  Padding,
  Section,
} from '@/features/new-landing-page/components/section';
import { StrapiFaq } from '@/features/strapi/components/faq';
import { VSpace } from '@/components/spacing';

export function PricingFaqSection() {
  return (
    <Section>
      <VSpace value={6} mobileValue={4} />
      <StrapiTextual {...PricingFaqContent.title} mobileMultiplier={0.6} />
      <VSpace value={2} />
      <Padding padding="0 3rem">
        <StrapiFaq {...PricingFaqContent.questions} />
      </Padding>
      <VSpace value={8} />
    </Section>
  );
}
